.main-title {
  display: block;
  font-family: 'PlayFair Display', sans-serif !important;
  text-align: left;
  color: $theme-dark;
  padding-bottom: 20px;
  margin-bottom: 20px !important;
  border-bottom: .5px solid rgba($theme-dark, .5); }

@media screen and (max-width: $responsive-md - 1px) {
  .main-title {
    text-align: center;
    margin: 0 !important;
    border-bottom: none; } }

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
  color: $theme-dark;
  font-family: Arial, sans-serif;

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 10px; }

  .logo {
    margin: 30px;
    width: 200px;
    animation-name: spin;
    animation-duration: 15000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; }

  .title {
    font-size: 20px;
    font-weight: normal;
    line-height: 40px;

    .block {
      letter-spacing: 1px;
      background-color: $theme-accent;
      color: $theme-light;
      padding: 4px 15px;
      border-radius: 10px;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
      transition: .3s;

      &:hover {
        opacity: .75; } } } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@import 'variables/_';
@import 'pages/_';
@import 'components/_';
@import 'custom';

.link {
  text-decoration: none !important; }

body {
  background: $theme-light;
  color: $theme-dark;
  font-family: 'Roboto', sans-serif; }

main {
  padding: 30px;
  margin-left: $drawer-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.link {
  text-decoration: none; }

@media screen and (max-width: $responsive-md - 1px) {
  main {
    padding: 17.5px;
    margin-left: 0;
    margin-top: $appbar-height; } }


@media screen and (max-width: $responsive-sm - 1px) {
  main {
    padding: 10px; } }

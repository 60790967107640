.drawer {
  .paper {
    width: $drawer-width; }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 10px 20px 10px;

    .avatar {
      width: 100px;
      height: 100px;
      transition: .3s;

      &:hover {
        width: 125px;
        height: 125px; } }

    .chip {
      margin: 20px 0; }

    .info {
      text-align: center;

      &:hover > div {
        transition: .3s;
        color: $theme-accent; }

      .title {
        color: $theme-dark;
        font-family: 'PlayFair Display', sans-serif;
        font-size: 18px;
        letter-spacing: .5px;
        padding: 10px 0; }

      .subtitle {
        color: rgba($theme-dark, .75);
        font-family: 'Roboto', sans-serif;
        font-size: 15px; } } }

  .lists {
    .list_item {
      color: $theme-dark;
      padding-left: 30px; } } }

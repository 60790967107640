.dashboard {
  .profile {

    .header {
      border-left: 3px solid rgba($theme-accent, .75);
      padding: 15px 0 12.5px 20px;
      background: rgba($theme-accent, .075);

      .title {
        color: $theme-dark;
        font-family: 'PlayFair Display', sans-serif;
        margin-bottom: 10px; }

      .subtitle {
        color: rgba($theme-accent, .75);
        font-family: 'Roboto', sans-serif;
        letter-spacing: .25px; } }

    .body {
      font-family: 'Roboto', sans-serif;
      margin: 20px 0;

      .title {
        color: rgba($theme-dark, .3);
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .25px;
        margin: 15px 0; }

      table {
        border-collapse: collapse;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: .3px;
        font-size: 14px;
        text-align: left;

        tr {
          line-height: 30px; }

        th {
          padding: 0 4vw 0 3px;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          display: list-item;
          list-style-type: circle;
          list-style-position: inside; }

        a {
          text-decoration: none;
          color: $theme-accent;
          font-weight: 500; } } } } }

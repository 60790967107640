::selection {
  background: $theme-accent;
  color: $theme-light; }

// scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px; }

::-webkit-scrollbar:horizontal {
  height: 7px; }

::-webkit-scrollbar-button {
  display: none; }

::-webkit-scrollbar-track {
  background: $theme-light; }

::-webkit-scrollbar-track-piece {
  background: 0 0; }

::-webkit-scrollbar-thumb {
  background: rgba($theme-dark, .25);
  border-radius: 10px; }

::-webkit-scrollbar-corner {
  display: none; }

.input-file {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  .upload-file-label {
    input {
      text-align: center;
      font-size: 12px;
      height: 10px; } } }

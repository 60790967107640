$rich-text-editor-height: 50vh;

@mixin rich-text-editor-style {
  height: $rich-text-editor-height;
  line-height: 25px;
  text-align: justify;
  text-indent: 40px;
  font-family: "Times New Roman", "Arial", sans-serif; }

.rich-text-editor {
  .ck-editor {
    .ck-editor__editable_inline {
      @include rich-text-editor-style; } }

  .preview {
    @include rich-text-editor-style;
    border: 1px solid rgba($theme-dark, .3);
    border-radius: 5px;
    color: rgba($theme-dark, .75);
    padding: 0 15px;
    overflow-y: auto; } }

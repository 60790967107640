.material-table {
  thead {
    th:first-child {
      padding: 0 25px; } }

  tbody {
    td:first-child {
      padding: 0 25px;

      button:first-child {
        margin-left: 8px; } } }

  .MuiTableRow-root[mode=add], .MuiTableRow-head {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important; }

  .MuiTableCell-paddingNone {
    color: $theme-dark; }

  .toolbar {} }

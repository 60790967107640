.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 18vh;
  text-align: center;

  .content {
    width: 250px; }

  .welcome {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: $theme-dark; }

  .avatar {
    margin: 30px auto 0 auto;
    width: 110px;
    height: 110px; }

  form {
    display: flex;
    flex-direction: column;

    .input-field {
      margin-top: 25px; } } }
